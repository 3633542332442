<template>
  <b-table
    sticky-header="true"
    :no-border-collapse="true"
    striped
    :items="roles"
    :fields="tableColumns"
    responsive
  >
    <template #cell(isCore)="data">
      <b-form-checkbox
        :checked="data.item.is_core == 1 ? true : false"
        :disabled="true"
      >
      </b-form-checkbox>
    </template>

    <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'Roles')"
            variant="outline-secondary"
            type="button"
            @click="setRole(data.item.id, 'edit')"
          >
            {{ $t('forms.edit') }}
          </b-button>
          <b-button
            v-if="$can('delete', 'Roles')"
            class="ml-1"
            variant="outline-danger"
            type="button"
            @click="setRole(data.item.id, 'del')"
          >
            {{ $t('forms.delete') }}
          </b-button>
        </template>
  </b-table>

</template>

<script>
import {
   BTable, BButton, BFormCheckbox
} from 'bootstrap-vue'

export default {
  components:{
    BTable, BButton, BFormCheckbox
  },
  props:{
    roles: {
      type: Array,
      required: true,
    },
  },
  data(){
    return {
      tableColumns : [
          { key: 'name'},
          { key: 'isCore' },
          { key: 'actions' },
      ]
    }
  },
  methods: {
    setRole(role_id, action){
      this.$emit('set-role', role_id, action);
    }
  }
}
</script>

<style>

</style>