<template>
<div>
  <b-button
        v-if="$can('create', 'Roles')"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="button"
        @click="addRole"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        {{ $t('forms.add_model', {model: $t('models.role')})}}
      </b-button>
  <template v-if="show_form">
    <hr>
    <validation-observer
        ref="refFormObserver"
      >
      <b-form
        style="margin:20px 0;"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Role Name"
              rules="required"
            >
              <b-form-group
                label="Role Name"
                label-for="role_name"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="role_name"
                  v-model="role_name"
                  placeholder="Enter new role name"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <b-form-checkbox
                id="role_isCore"
                v-model="role_isCore"
                plain
              >
                Belongs to Smile/Core
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      
          <!-- Action Buttons -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              type="button"
              @click="saveRole"
              :disabled="role_name ? false : true"
            >
              {{ $t('forms.save') }}
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              type="button"
              @click="closeForm"
            >
              {{ $t('forms.cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </template>
  <hr>
  <roles-table 
    :roles="roles" 
    @set-role="setRole"
  />
</div>
</template>

<script>
import { saveRole, deleteRole } from '@/api/routes'

import RolesTable from './RolesTable.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  BFormGroup,
  BFormInput, 
  BFormCheckbox,
  BButton, 
  BRow, BCol,
  BFormInvalidFeedback,
  BForm,
} from 'bootstrap-vue'
export default {
  name: 'RolesTab',
  components: {
    BFormGroup,
    BFormInput, 
    BFormCheckbox,
    BButton, 
    BRow, BCol,
    BFormInvalidFeedback,
    BForm,

    RolesTable,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    roles: {
      type:Array,
      required: true,
    }
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
  },
  data(){
    return {
      required,

      role_id: '',
      role_name: '',
      role_isCore:false,

      show_form: false,
    }
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    saveRole(){
      let data = {
        'role_id': this.role_id,
        'role_name': this.role_name,
        'role_isCore': this.role_isCore
      }
      this.$http.post(saveRole, data).then( response =>{
        // console.log(response)
        this.$emit('set-data', response);
        this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));

        this.role_id = '';
        this.role_name = '';
        this.role_isCore = false;
        this.show_form = false;
      }).catch(error => {
        // console.log("Err -> ", error)
      })
    },
    setRole(role_id,action){
      if(action == 'edit'){
        this.roles.map( x => {
          if(x.id == role_id){
            this.role_id = x.id;
            this.role_name = x.name;
            this.role_isCore = x.isCore == 1 ? true : false;
          }
        })
        this.show_form = true;
      } else {
        this.deleteRole(role_id);
      }
    },
    deleteRole(role_id){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_one_confirmation'), {
          title: this.$t('forms.delete'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'rol_id': role_id
            }
            this.$http.post(deleteRole, data).then( response =>{
              // console.log(response)
              if(response.data.roles){
                this.$emit('set-data', response);
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
          // console.log(value, this.selected)
        })
    },
    closeForm(){
      this.show_form = false;
      this.role_id = '';
      this.role_name = '';
      this.role_isCore = false;
    },
    addRole(){
      this.show_form = true;
      this.role_id = '';
      this.role_name = '';
      this.role_isCore = false;
    }
  }
}
</script>

<style>

</style>